<template>
  <div class='JZContainer'>
    <content-block >
      <div slot="title" class="content_title"> 基本资料 <el-button type='default' size="mini" style="margin-left:20px;" @click="isEditing=true" v-if="!isEditing">编辑</el-button></div>
      <el-form label-width="80px" :model="JZBasicForm" ref="JZBasicForm" :rules="JZBasicFormRules" label-position="right" style="width:600px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="姓名：" prop="name">
              <el-input v-if="isEditing" v-model.trim="JZBasicForm.name" maxlength="30" show-word-limit placeholder="请输入姓名"></el-input>
              <span v-else>{{JZBasicForm.name}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="性别：" prop="gender">
              <el-radio-group  v-if="isEditing" v-model.trim="JZBasicForm.gender">
                <el-radio  v-for="sex in sexLists" :key="sex.value" :value='sex.value' :label="sex.value">{{sex.label}}</el-radio>
              </el-radio-group>
              <span v-else>{{JZBasicForm.gender==='M'?'男':JZBasicForm.gender==='F'?'女':''}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="邮箱：" prop="email">
              <el-input v-if="isEditing" v-model.trim="JZBasicForm.email" maxlength="100" show-word-limit placeholder="请输入邮箱"></el-input>
              <span v-else>{{JZBasicForm.email}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="地址：" prop="address">
              <div v-if="isEditing">
                <area-cascader
                  style="width:215px;display:inline-block;"
                  :includeCountry='false'
                  :provinceId.sync="JZBasicForm.provinceId"
                  :provinceName.sync="JZBasicForm.provinceName"
                  :cityId.sync="JZBasicForm.cityId"
                  :cityName.sync="JZBasicForm.cityName"
                  :areaId.sync="JZBasicForm.areaId"
                  :areaName.sync="JZBasicForm.areaName"
                />
                <el-input style="width:215px;display:inline-block;margin-left:10px" maxlength="100" show-word-limit   v-model.trim="JZBasicForm.address" placeholder="请输入地址"></el-input>
              </div>
              <span v-else>{{JZBasicForm.provinceName}}{{JZBasicForm.cityName}}{{JZBasicForm.areaName}}{{JZBasicForm.address}}</span>
            </el-form-item>
          </el-col>
           <el-col :span="24" v-if="isEditing" class="text-center">
            <el-button type="default" size="mini" @click="isEditing=false">取消</el-button>
            <el-button type="primary" size="mini" @click="handleSubmit">确认</el-button>
          </el-col>
        </el-row>
      </el-form>
    </content-block>
    <content-block title="账号密码" v-if="!isEditing">
      <el-form label-width="80px" label-position="right" style="width:400px">
        <el-row>
          <el-col :span="24" :model='info'>
            <el-form-item label="手机号：" prop="account">
              {{info.account}}
              <el-button  type="default" size='mini' class="fr" @click="handleEdit(1)">编辑</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="密码：" prop="password">
              ******
              <el-button type="default" size='mini' class="fr" @click="handleEdit(2)">编辑</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </content-block>
  <ModifyPhone ref="modifyPhone" @getDetailInfo='handleLogout'></ModifyPhone>
  <ModifyPassword ref="modifyPassword" @getDetailInfo='handleLogout'></ModifyPassword>
  </div>
</template>

<script>
import {detailUser, updateUser, logout} from '@/api/userCenter';
import AreaCascader from '@/components/common/areaCascader/index.vue';
import ModifyPhone from './components/ModifyPhone.vue';
import ModifyPassword from './components/ModifyPassword.vue';
export default {
  name: 'PersonalCenter',
  components: {
    AreaCascader,
    ModifyPhone,
    ModifyPassword,
  },
  created () {
    this.$store.dispatch('changeDefaultActive', this.$route.name);
    this.getDetailInfo();
  },
  methods: {
    handleSubmit () {
      this.$refs.JZBasicForm.validate(valid => {
        if (valid) {
          updateUser({
            name: this.JZBasicForm.name,
            gender: this.JZBasicForm.gender,
            genderName: '',
            email: this.JZBasicForm.email,
            address: this.JZBasicForm.address,
            provinceId: this.JZBasicForm.provinceId,
            provinceName: this.JZBasicForm.provinceName,
            cityId: this.JZBasicForm.cityId,
            cityName: this.JZBasicForm.cityName,
            areaId: this.JZBasicForm.areaId,
            areaName: this.JZBasicForm.areaName,
          }).then(() => {
            this.isEditing = false;
            let currentInfo = this.userInfo;
            currentInfo = {
              ...currentInfo,
              name: this.JZBasicForm.name,
              gender: this.JZBasicForm.gender,
              email: this.JZBasicForm.email,
              address: this.JZBasicForm.address,
              identity: this.$store.state.User.userInfo.identity,
            };
            this.$store.dispatch('changeUserInfo', currentInfo);
            this.$JZShowAlert('更新信息成功！', 'success');
          });
        }
      });
    },
    handleEdit (status) {
      this.status = status;
      console.log(this.info.account);
      if (status === 1) {
        this.$refs.modifyPhone.JZChangePhoneForm = {
          code: '',
          newPhone: '',
          newCode: '',
          phone: this.info.account,
        };
        this.$refs.modifyPhone.initPage();
      } else {
        this.$refs.modifyPassword.JZChangePasswordForm = {
          phone: this.info.account,
          originalPassword: '',
          newPassword: '',
          confirmPassword: '',
          code: '',
        };
        this.$refs.modifyPassword.initPage();
      }
    },
    getDetailInfo () {
      detailUser({}).then(res=>{
        let currentInfo = res.body;
        currentInfo['identity'] = this.$store.state.User.userInfo.identity;
        this.userInfo = currentInfo;
        this.$store.dispatch('changeUserInfo', currentInfo);
        this.JZBasicForm = {
          name: currentInfo.name,
          gender: currentInfo.gender,
          genderName: '',
          email: currentInfo.email,
          address: currentInfo.address,
          provinceId: currentInfo.provinceId,
          provinceName: currentInfo.provinceName,
          cityId: currentInfo.cityId,
          cityName: currentInfo.cityName,
          areaId: currentInfo.areaId,
          areaName: currentInfo.areaName,
        };
        this.info = {
          account: currentInfo.phone,
        };
      });
    },
    handleLogout () {
      logout({}).then(() => {
        this.$local.clear();
        this.$session.clear();
        this.$router.push({
          name: 'Login',
        });
      });
    },
  },
  data () {
    return {
      info: {
        account: '',
        password: '',
      },
      loginMsgTimerId: null,
      loginConfirmPasswordTimerId: null,
      userInfo: {},
      loginMsgTip: '发送短信验证码',
      oldMsgTip: '发送验证码',
      newMsgTip: '发送验证码',
      isEditing: false,
      isShow: false,
      isCode: 'password',
      JZBasicForm: {
        name: '',
        gender: 'F',
        genderName: '',
        email: '',
        address: '',
        provinceId: '',
        provinceName: '',
        cityId: '',
        cityName: '',
        areaId: '',
        areaName: '',
      },
      JZBasicFormRules: {
        name: [
          {
            required: true,
            message: '请输入姓名',
            trigger: ['blur', 'change'],
          },
        ],
        gender: [
          {
            required: true,
            message: '请选择性别',
            trigger: ['blur', 'change'],
          },
        ],
        email: [
          {
            type: 'email',
            message: '邮箱格式不正确',
            trigger: ['blur', 'change'],
          },
        ],
      },
      sexLists: [
        {
          label: '男',
          value: 'M',
        },
        {
          label: '女',
          value: 'F',
        },
      ],
      status: '',
      JZChangePasswordForm: {
        originalPassword: '',
        newPassword: '',
        confirmPassword: '',
        phone: '',
        code: '',
      },
      JZChangePhoneForm: {
        phone: '',
        code: '',
        newPhone: '',
        newCode: '',
      },
      JZChangePhoneFormRules: {
        phone: [
          {
            required: true,
            message: '请输入原手机号',
            trigger: ['blur', 'change'],
          },
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: ['blur', 'change'],
          },
        ],
        newPhone: [
          {
            required: true,
            message: '请输入新手机号',
            trigger: ['blur', 'change'],
          },
          {
            pattern: /^1\d{10}$/,
            message: '新手机号码格式不正确',
            trigger: ['blur', 'change'],
          },
        ],
        newCode: [
          {
            required: true,
            message: '请输入验证码',
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
/deep/ .content_title{
    display: flex;
    align-items: center;
    height: 36px;
    margin-bottom: 16px;
    font-size: 14px;
    &::before{
      width: 5px;
      height: 24px;
      content: '';
      background: #2577e3;
      margin-right: 10px;
    }
}
// /deep/ .el-form-item--small.el-form-item{
//   display: flex;
// }
// /deep/ .el-form-item--small .el-form-item__content{
//   flex: 1;
// }
/deep/ .text-center{
  text-align: center;
}
/deep/ .el-form .el-form-item--small.el-form-item{
  display: flex;
}
/deep/ .el-form .el-form-item--small .el-form-item__content{
  flex: 1;
}
</style>
