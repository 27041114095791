<!--
 * @Author: your name
 * @Date: 2022-04-18 15:22:53
 * @LastEditTime: 2022-04-18 15:23:04
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /seller-enter-web/src/views/PersonalCenter/components/ModifyPassword.vue
-->

<template>
  <el-dialog
    :title="currentTitle"
    :visible.sync="isShow"
    width="500px"
    :append-to-body='true'
    :destroyOnClose='true'
    :close-on-click-modal='true'
  >
    <div class="content" style="max-height: 400px;overflow: auto;">
      <el-form
        :model="JZChangePasswordForm"
        :rules="JZChangePasswordFormRules"
        :inline="true"
        ref='JZForm'
        class="JZForm1"
        label-width="125px"
      >
        <ul class="navContainer">
          <li  v-for="tab in  tabLists" :key="tab.value" @click="handleTabClick(tab.value)" :class="{'active': isCode === tab.value}">{{tab.label}}</li>
        </ul>
        <el-row v-if="isCode==='password'">
          <el-col :span="24">
            <el-form-item
                prop="originalPassword"
                label="原密码："
              >
              <el-input show-password type="password" v-model.trim="JZChangePasswordForm.originalPassword" placeholder="请输入原密码" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
                prop="newPassword"
                label="新密码："
              >
            <el-input show-password type="password" v-model.trim="JZChangePasswordForm.newPassword" placeholder="请输入新密码" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
                prop="confirmPassword"
                label="重复密码："
              >
            <el-input show-password type="password" v-model.trim="JZChangePasswordForm.confirmPassword" placeholder="请输入重复密码" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-else>
          <el-col :span="24">
            <el-form-item prop="phone" label="手机号：">
              <el-input placeholder="请输入手机号" disabled v-model="JZChangePasswordForm.phone" maxlength='11' show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" >
            <el-form-item prop="code" label="短信验证码：">
            <el-input placeholder="请输入短信验证码" v-model="JZChangePasswordForm.code">
              <span slot="append" class="cursor-pointer" @click="sendCaptcha">{{oldMsgTip}}</span>
            </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
                prop="newPassword"
                label="新密码："
              >
            <el-input show-password type="password" v-model.trim="JZChangePasswordForm.newPassword" placeholder="请输入新密码" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
                prop="confirmPassword"
                label="确认密码："
              >
            <el-input show-password type="password" v-model.trim="JZChangePasswordForm.confirmPassword" placeholder="请输入确认密码" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer" style="text-align:right;" >
        <el-button @click="isShow=false" size="mini">取 消</el-button>
        <el-button type="primary" @click="handleEnsure" size="mini">确定</el-button>
      </span>
  </el-dialog>
</template>

<script>
import {changePassword} from '@/api/userCenter';
import { sendCaptcha} from '@/api/login';
export default {
  name: '',
  methods: {
    initPage () {
      this.isCode = 'password';
      this.isShow = true;
      this.$nextTick(() => {
        this.$refs.JZForm.clearValidate();
      });
    },
    handleEnsure () {
      this.$refs.JZForm.validate(valid => {
        if (valid) {
          let currentParams = {
            code: this.JZChangePasswordForm.originalPassword || this.JZChangePasswordForm.code,
            password: this.JZChangePasswordForm.newPassword,
            type: this.isCode === 'password' ? 'PASSWORD' : 'CAPTCHA',
          };
          changePassword(currentParams).then(() => {
            this.status = '';
            this.isShow = false;
            let currentInfo = this.userInfo;
            currentInfo = {
              ...currentInfo,
              phone: this.JZChangePasswordForm.phone,
              identity: this.$store.state.User.userInfo.identity,
            };
            this.$store.dispatch('changeUserInfo', currentInfo);
            this.$emit('getDetailInfo');
          });
        }
      });
    },
    handleTabClick (value) {
      this.isCode = value;
      this.$nextTick(() => {
        this.JZChangePasswordForm.originalPassword = '';
        this.JZChangePasswordForm.newPassword = '';
        this.JZChangePasswordForm.confirmPassword = '';
        this.JZChangePasswordForm.code = '';
        this.$refs.JZForm.clearValidate();
      });
    },
    sendCaptcha () {
      let captchaType = 'CHANGE_PASSWORD';
      let phone = this.JZChangePasswordForm.phone;
      sendCaptcha({ phone, captchaType }).then(() =>{
        if (!this.loginMsgTimerId) {
          this.loginMsgTimerTick = 120;
          this.loginMsgTimerId = setInterval(() => {
            this.loginMsgTimerTick--;
            this.oldMsgTip = `重新获取(${this.loginMsgTimerTick})`;
            if (this.loginMsgTimerTick === 0) {
              clearInterval(this.loginMsgTimerId);
              this.loginMsgTimerId = null;
              this.oldMsgTip = '获取验证码';
            }
          }, 1000);
        }
      });
    },
    validateConfirmPassword (rule, value, callback) {
      if (value) {
        if (value !== this.JZChangePasswordForm.newPassword) {
          callback(new Error('重复密码与新密码保持一致!'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
  },
  computed: {
    JZChangePasswordFormRules () {
      let rule = {
        originalPassword: [
          {
            required: this.isCode === 'password',
            message: '请输入原密码',
            trigger: ['blur', 'change'],
          },
        ],
        code: [
          {
            required: this.isCode === 'code',
            message: '请输入短信验证码',
            trigger: ['blur', 'change'],
          },
        ],
        newPassword: [
          {
            required: true,
            message: '请输入新密码',
            trigger: ['blur', 'change'],
          },
          {
            pattern: /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{8,16}$/,
            message: '新密码8-16位字母数字组成，必须含有字母大小写与数字',
            trigger: ['blur', 'change'],
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: '请输入重复密码',
            trigger: ['blur', 'change'],
          },
          {
            pattern: /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{8,16}$/,
            message: '重复密码8-16位字母数字组成，必须含有字母大小写与数字',
            trigger: ['blur', 'change'],
          },
          {
            validator: this.validateConfirmPassword,
            message: '重复密码与新密码保持一致',
            trigger: ['blur', 'change'],
          },
        ],
      };
      return rule;
    },
  },
  data () {
    return {
      currentTitle: '修改密码',
      isShow: false,
      isCode: 'password',
      oldMsgTip: '获取验证码',
      loginMsgTimerId: null,
      JZChangePasswordForm: {
        originalPassword: '',
        newPassword: '',
        confirmPassword: '',
        phone: '',
        code: '',
      },
      tabLists: [
        {
          label: '根据密码修改',
          value: 'password',
        },
        {
          label: '手机验证码修改密码',
          value: 'code',
        },
      ],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
.navContainer{
  list-style: none;
  margin-bottom: 10px;
  height: 40px;
  li{
    position: relative;
    height: 40px;
    line-height: 40px;
    color: #999;
    cursor: pointer;
    font-size: 14px;
    margin: 0 10px;
    float: left;
    &.active{
      color: #409eff;
      font-weight: bold;
      &::after{
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 2px;
        width: 100%;
        content: '';
        background: #409eff;
      }
    }
  }
}
</style>
