import server from '@/request';

// 手机号密码-验证码登陆
function phoneLogin (params) {
  return server.post('/xiaozi-seller/wireless/user/phoneLogin', params);
}

function sendCaptcha (params) {
  return server.post('/xiaozi-seller/wireless/user/sendCaptcha', params);
}

export {phoneLogin, sendCaptcha};
